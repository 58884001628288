export const USER_ACCOUNT_CACHE_KEY = 'careapp-user'
export async function clearCareAppUserAccountCache() {
  return window.caches
    .delete(USER_ACCOUNT_CACHE_KEY)
    .then((result) => result)
    .catch((error) => {
      // TODO: Catch with error monitoring
      console.error(error)
      return false
    })
}
