import { ReactNode, useState } from 'react'
import {
  FieldPath,
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { AlertTooltip } from '@shared/components/Tooltips/AlertTooltip'
import { hasWeightSignificantlyChanged } from '@shared/components/Vitals/helpers'
import { LatestVital } from '@shared/types/latest_vital'
import { Loading } from '@shared/utils/loading'
import { twx } from '@shared/utils/tailwind'

export type VitalsInputProps<Form extends FieldValues> = {
  register: UseFormRegister<Form>
  watch: UseFormWatch<Form>
  onBlur: () => void
  isRequired?: boolean
  disabled?: boolean
  name: FieldPath<Form>
  children?: ReactNode
  iconClassName: string
  label: string
  alertTooltip?: ReactNode
  inputWrapperClasses?: string
  showTooltips?: boolean
  validate: (value: string) => string | boolean
}
export function VitalsInput<Form extends FieldValues>(
  props: VitalsInputProps<Form>
) {
  const {
    register,
    watch,
    onBlur,
    isRequired,
    disabled,
    name,
    label,
    iconClassName,
    alertTooltip,
    inputWrapperClasses,
    children,
    showTooltips,
    validate,
  } = props

  const iconClasses = twx(iconClassName, 'fa-fw text-primary-light')
  const inputWrapperClassName = twx('flex items-center', inputWrapperClasses)

  const inputHasValue = Boolean(watch(name))
  const inputClasses = getInputClasses(inputHasValue)

  return (
    <div className={twx('flex items-center')}>
      <i className={twx(iconClasses)} />
      <VitalsLabel name={name} label={label} />
      <div className={twx(inputWrapperClassName)}>
        {showTooltips && alertTooltip}
        <BasicInput
          type="number"
          className={twx(inputClasses)}
          id={name}
          min={0}
          step="any"
          inputSize={'inline'}
          disabled={disabled}
          {...register(name, {
            required: isRequired,
            onBlur,
            validate,
          })}
        />
        {children}
      </div>
    </div>
  )
}
function VitalsLabel<Form extends FieldValues>(props: {
  name: FieldPath<Form>
  label: string
}) {
  const { name, label } = props
  return (
    <label
      htmlFor={name as string}
      className={twx(
        'mx-[8px] my-0 whitespace-nowrap text-[14px] font-medium leading-[28px]'
      )}
    >
      {label}:
    </label>
  )
}
export function BloodPressureVitalsInput<Form extends FieldValues>(
  props: VitalsInputProps<Form> & {
    diastolicName: FieldPath<Form>
  }
) {
  const { register, onBlur, validate, watch, diastolicName, ...rest } = props

  const diastolicHasValue = Boolean(watch(diastolicName))
  const diastolicInputClasses = getInputClasses(diastolicHasValue)
  const required = diastolicHasValue

  return (
    <VitalsInput
      onBlur={onBlur}
      isRequired={required}
      register={register}
      watch={watch}
      validate={validate}
      {...rest}
    >
      <span className={twx(`mx-[4px] my-0 text-gray-08`)}>/</span>
      <BasicInput
        className={twx(diastolicInputClasses)}
        id={diastolicName}
        type="number"
        min={0}
        step="any"
        inputSize={'inline'}
        disabled={rest.disabled}
        {...register(diastolicName, {
          required,
          validate,
          onBlur,
        })}
      />
    </VitalsInput>
  )
}
export function WeightVitalsInput<Form extends FieldValues>(
  props: VitalsInputProps<Form> & {
    latestVitals: Loading<LatestVital>
    getValues: UseFormGetValues<Form>
  }
) {
  const { onBlur, latestVitals, getValues, ...rest } = props

  const [weightAlert, setWeightAlert] = useState<
    'Never shown' | 'Showing' | 'Dismissed'
  >('Never shown')

  const checkWeightDifference = () => {
    if (weightAlert === 'Dismissed') {
      return
    }

    const data = getValues()
    if (
      latestVitals.tag === 'Complete' &&
      data.weight &&
      hasWeightSignificantlyChanged({
        weight: data.weight as string,
        date: data.date as Date,
        oldData: latestVitals.value,
      })
    ) {
      setWeightAlert('Showing')
    }
  }
  const inputOnBlur = () => {
    checkWeightDifference()
    onBlur()
  }

  const alertToolTip = weightAlert === 'Showing' && (
    <AlertTooltip
      className={twx('absolute left-[-6px] top-[calc(100%+12px)]')}
      onClose={() => {
        setWeightAlert('Dismissed')
      }}
      caretPosition={'top'}
    />
  )

  return (
    <VitalsInput
      onBlur={inputOnBlur}
      inputWrapperClasses={twx('relative')}
      alertTooltip={alertToolTip}
      {...rest}
    />
  )
}
export const getInputClasses = (hasValue: boolean) => {
  return twx(
    'bg-transparent border-0 border-b border-solid border-gray-08 text-formInput max-w-[64px] min-w-[48px] rounded-b-none h-vitalInput px-[8px] py-[14px]',
    'hover:outline-0 focus:outline-0 focus-visible:outline-0 active:outline-0',
    {
      ['border-b-transparent text-gray-03 text-formInput']: hasValue,
    }
  )
}
