import React, { HTMLProps } from 'react'
import type { ClassValue } from 'clsx'
import { BasicSpinner } from '@shared/components/BasicSpinner'
import Icon, { IconVariant } from '@shared/components/Icon'
import { twx } from '@shared/utils/tailwind'

export type DataAttributeKey = `data-${string}`

export type ButtonIconPosition = 'left' | 'right'

export type ButtonStyle =
  | 'primary-fill'
  | 'primary-light-fill'
  | 'primary-fill-inverted'
  | 'primary-outline'
  | 'secondary-outline'
  | 'secondary-fill'
  | 'secondary-fill-inverted'
  | 'tertiary-outline'
  | 'tertiary-fill'
  | 'danger-fill'
  | 'danger-outline'
  | 'icon'
  | 'link'

export type ButtonSize = 'xsmall' | 'small' | 'medium' | 'xlarge'
export type ButtonType = 'button' | 'submit' | 'reset'

export interface Props extends Omit<HTMLProps<HTMLButtonElement>, 'onClick'> {
  isLoading?: boolean
  initialIcon?: string
  initialIconVariant?: IconVariant
  initialIconPosition?: ButtonIconPosition
  initialIconClassName?: string
  supplementalIcon?: string
  supplementalIconPosition?: ButtonIconPosition
  supplementalIconClassName?: string
  buttonStyle: ButtonStyle
  buttonSize?: ButtonSize
  width?: string
  type?: ButtonType
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>
  [dataAttribute: DataAttributeKey]: string
}

export const sharedButtonClassLists = (
  buttonStyle: ButtonStyle,
  buttonSize: ButtonSize
): Array<ClassValue> => {
  return [
    {
      'h-[24px] rounded-[4px] px-[10px] py-[7px] text-[9px] font-semibold leading-[10px]':
        buttonSize === 'xsmall',
      'h-[32px] rounded-[5px] px-[12px] py-[10px] text-[11px] font-semibold leading-[12px]':
        buttonSize === 'small',
      'h-[40px] rounded-[6px] px-[16px] py-[14px] text-[11px] font-bold leading-[12px]':
        buttonSize === 'medium',
      'h-[56px] rounded-[8px] px-[28px] py-[20px] text-[16px] font-semibold leading-[16px]':
        buttonSize === 'xlarge',
    },
    {
      'border-0 bg-transparent text-link hover:opacity-50 p-0 m-0 inline':
        buttonStyle === 'link',
      'border-0 bg-transparent text-secondary-07 hover:opacity-50 p-2 m-0':
        buttonStyle === 'icon',
      'border-0 bg-august-primary text-white hover:brightness-90':
        buttonStyle === 'primary-fill',
      'border-0 bg-primary-light text-white hover:brightness-90':
        buttonStyle === 'primary-light-fill',
      'border-0 bg-white text-black hover:brightness-90':
        buttonStyle === 'primary-fill-inverted',
      'border border-august-primary bg-transparent text-august-primary hover:bg-scrim-white hover:outline hover:outline-1 hover:outline-august-primary':
        buttonStyle === 'primary-outline',
      'border border-secondary bg-transparent text-secondary hover:bg-scrim-white hover:outline hover:outline-1 hover:outline-secondary':
        buttonStyle === 'secondary-outline',
      'border-0 bg-secondary text-white hover:brightness-90':
        buttonStyle === 'secondary-fill',
      'border border-button-secondary-inverted-border bg-button-secondary-inverted-background text-white hover:brightness-90':
        buttonStyle === 'secondary-fill-inverted',
      'border border-gray-10 bg-transparent text-gray-05 hover:bg-scrim-white hover:outline hover:outline-1 hover:outline-gray-10':
        buttonStyle === 'tertiary-outline',
      'text-gray-05 border-0 bg-gray-11 hover:brightness-90':
        buttonStyle === 'tertiary-fill',
      'border-0 bg-quaternary text-white hover:brightness-90':
        buttonStyle === 'danger-fill',
      'border border-danger bg-transparent text-danger hover:bg-scrim-white hover:outline hover:outline-1 hover:outline-button-quaternary-color':
        buttonStyle === 'danger-outline',
    },
    'disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50',
  ]
}

export function AsyncIconButton({
  buttonSize = 'medium',
  className,
  disabled,
  onClick,
  buttonStyle,
  isLoading,
  initialIcon,
  initialIconVariant = 'solid',
  initialIconPosition = 'left',
  initialIconClassName,
  supplementalIcon,
  supplementalIconPosition = 'right',
  supplementalIconClassName,
  children,
  type = 'button',
  width,
  ...rest
}: Props) {
  const hasBtnNode =
    children &&
    (typeof children === 'string' ||
      (Array.isArray(children) && children.some((node) => node)))

  return (
    <button
      /* eslint-disable-next-line no-restricted-syntax */
      style={{ width }}
      disabled={disabled}
      className={twx(
        'inline-flex items-center justify-center uppercase',
        ...sharedButtonClassLists(buttonStyle, buttonSize),
        className
      )}
      onClick={onClick}
      type={type}
      {...rest}
    >
      {isLoading && (
        <BasicSpinner
          className={twx('fa-fw', {
            'mr-[6px]': buttonSize === 'small' || buttonSize === 'xsmall',
            'mr-[8px]': buttonSize === 'medium',
            'mr-[10px]': buttonSize === 'xlarge',
          })}
        />
      )}
      {!isLoading && initialIcon && initialIconPosition === 'left' && (
        <Icon
          name={initialIcon}
          variant={initialIconVariant}
          className={twx(
            {
              'mr-[6px]':
                (buttonSize === 'small' || buttonSize === 'xsmall') &&
                hasBtnNode,
              'mr-[8px]': buttonSize === 'medium' && hasBtnNode,
              'mr-[10px]': buttonSize === 'xlarge' && hasBtnNode,
            },
            initialIconClassName
          )}
        />
      )}
      {supplementalIcon && supplementalIconPosition === 'left' && (
        <Icon
          name={supplementalIcon}
          className={twx(
            {
              'mr-[6px]': buttonSize === 'small' || buttonSize === 'xsmall',
              'mr-[8px]': buttonSize === 'medium',
              'mr-[10px]': buttonSize === 'xlarge',
            },
            supplementalIconClassName
          )}
        />
      )}
      {children}
      {!isLoading && initialIcon && initialIconPosition === 'right' && (
        <Icon
          name={initialIcon}
          className={twx(
            {
              'ml-[6px]':
                (buttonSize === 'small' || buttonSize === 'xsmall') &&
                hasBtnNode,
              'ml-[8px]': buttonSize === 'medium' && hasBtnNode,
              'ml-[10px]': buttonSize === 'xlarge' && hasBtnNode,
            },
            initialIconClassName
          )}
        />
      )}
      {supplementalIcon && supplementalIconPosition === 'right' && (
        <Icon
          name={supplementalIcon}
          className={twx(
            {
              'ml-[6px]': buttonSize === 'small' || buttonSize === 'xsmall',
              'ml-[8px]': buttonSize === 'medium',
              'ml-[10px]': buttonSize === 'xlarge',
            },
            supplementalIconClassName
          )}
        />
      )}
    </button>
  )
}
