import { MouseEvent } from 'react'
import { twx } from '@shared/utils/tailwind'

interface Props {
  subtitle?: React.ReactNode
  title: React.ReactNode
  children: React.ReactNode
  containerClassName?: string
  onClickBackground?: (ev: MouseEvent<HTMLDivElement>) => void
  testId?: string
}

export function AnimatedPopup({
  children,
  containerClassName = '',
  subtitle,
  title,
  onClickBackground,
  testId,
}: Props) {
  return (
    <div
      className={twx(
        'fixed bottom-0 left-0 right-0 top-0 z-[200] bg-transparent-overlay-background',
        'overflow-hidden print:absolute print:overflow-visible'
      )}
      onClick={onClickBackground}
      id={'animated-popup-bg'}
      data-testid={testId}
    >
      <div className={twx('motion-reduce:animation-none animate-fade-in')}>
        <div
          className={twx(
            'fixed z-[200] max-h-[90vh] w-defaultModal overflow-auto bg-secondary-13 p-8',
            'left-[50vw] top-[50vh] translate-x-[-50%] translate-y-[-50%] overscroll-contain rounded-[8px] shadow-popup',
            containerClassName
          )}
          onClick={(e) => e.stopPropagation()}
        >
          {typeof title === 'string' ? (
            <div
              className={twx(
                'mb-8 border-b border-[rgba(192,190,187,0.5)] pb-4'
              )}
            >
              <h2
                className={twx(
                  'mb-0 ml-1 inline-block text-[24px] font-medium leading-[36px] text-secondary-02'
                )}
              >
                {title}
              </h2>
              {subtitle && (
                <span className={twx('ml-2 font-medium text-secondary-07')}>
                  {subtitle}
                </span>
              )}
            </div>
          ) : (
            title
          )}
          {children}
        </div>
      </div>
    </div>
  )
}
