import { tw } from '@shared/utils/tailwind'

export default function Banner({
  children,
  description,
}: {
  children?: React.ReactNode
  description: {
    icon?: string
    copy: React.ReactNode
  }
}) {
  return (
    <div
      className={tw`order-none mb-4 flex flex-none flex-grow-0 flex-row items-center justify-between self-stretch rounded-lg bg-sidebar p-4 text-xs font-medium leading-4 text-banner`}
    >
      <div>
        {description.icon && (
          <i
            className={tw`fa-fw fas mr-2 h-4 w-4 text-center text-base font-black leading-4 text-white ${description.icon}`}
          />
        )}
        {description.copy}
      </div>
      {children}
    </div>
  )
}
