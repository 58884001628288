import TextInputWithIcon from '@shared/components/TextInputWithIcon/TextInputWithIcon'
import { tw, twx } from '@shared/utils/tailwind'
import { Label } from './Labels'

type Props = {
  onChange: (val: string) => void
  value: string
  clearIconClass?: string
  disabled?: boolean
  holderClassName?: string
  iconClassName?: string
  inputClassName?: string
  name?: string
  labelText?: string
  onFocus?: () => void
  placeholder?: string
  autoFocus?: boolean
}

export default function SearchBox({
  holderClassName,
  onChange,
  placeholder = 'Search...',
  value = '',
  clearIconClass = 'fa-times-circle',
  iconClassName,
  inputClassName,
  disabled = false,
  onFocus,
  name = 'search-box',
  labelText = 'Search',
  autoFocus,
}: Props) {
  return (
    <>
      <Label visuallyHidden htmlFor={name}>
        {labelText}
      </Label>
      <TextInputWithIcon
        holderClassName={tw`flex w-[240px] items-center rounded-[6px] border-0 bg-white text-[14px] leading-[16px] ${holderClassName}`}
        inputProps={{
          name,
          className: twx(
            'cursor-text border-form-input-border bg-transparent',
            inputClassName
          ),
          placeholder,
          onChange: (ev) => {
            const newVal = ev.currentTarget.value
            onChange(newVal)
          },
          value: value,
          disabled,
          onFocus: onFocus
            ? () => {
                onFocus()
              }
            : undefined,
          autoFocus,
        }}
        iconClassName={tw`fas fa-search text-primary-light ${iconClassName}`}
      >
        {value && (
          <i
            className={tw`fa ${clearIconClass} absolute right-[16px] top-[50%] mt-[-0.5em] cursor-pointer text-gray-07`}
            onClick={() => {
              onChange('')
            }}
          />
        )}
      </TextInputWithIcon>
    </>
  )
}
