import { twx } from '@shared/utils/tailwind'

type Props = React.HTMLAttributes<HTMLBaseElement> & {
  className?: string
  doNotAnimate?: boolean
}

export function BasicSpinner(props: Props) {
  const { className, doNotAnimate, ...rest } = props

  return (
    <i
      {...rest}
      className={twx(
        'fa-solid fa-spinner',
        {
          'fa-spin': !doNotAnimate,
        },
        className
      )}
    />
  )
}
