import { MouseEventHandler } from 'react'
import { tw } from '@shared/utils/tailwind'

type ButtonProps = React.ComponentProps<'button'> & {
  type?: string
  className?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
}
export default function Button(props: ButtonProps) {
  const {
    children,
    className = '',
    type = 'button',
    onClick = console.log,
  } = props

  return (
    <button
      onKeyPress={(event) => {
        if (event.key === 'Tab') {
          return
        }
        event.preventDefault()
        if (event.key === 'Enter') {
          onClick()
        }
      }}
      {...props}
      className={tw`rounded-[6px] px-[12px] py-[11px] text-[11px] font-bold leading-[14px] ${className}`}
      type={type}
    >
      {children}
    </button>
  )
}
