/* eslint-disable check-file/filename-naming-convention */
/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react'
import { userPrimaryRole } from '@shared/components/Intercom/helpers'
import {
  APP_ID,
  intercom_provided_code,
} from '@shared/components/Intercom/intercom_provided_code'
import environment from '@shared/environment'
import { Facility } from '@shared/types/facility'
import { UserAccount } from '@shared/types/user'
import { getFullName } from '@shared/utils/humanName'
import { Loading } from '@shared/utils/loading'
import { hasResponsiblePersonRole, isSuperUser } from '@shared/utils/user'

// https://developers.intercom.com/installing-intercom/docs/basic-javascript#section-single-page-app

function intercom_mock() {
  window.Intercom = function () {
    console.log('*** INTERCOM MOCK ***')
    console.log({
      method: arguments[0],
      arguments: arguments[1],
    })
  }
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    Intercom: any | undefined
  }
}

export const INTERCOM_LAUNCHER_ID = 'intercom_launcher'
export default function Intercom({
  user,
  currentFacility,
}: {
  user: UserAccount
  currentFacility: Loading<Facility> | undefined
}) {
  // Run intercom code only once
  useEffect(() => {
    if (environment.intercomEnabled) {
      intercom_provided_code()
    } else {
      intercom_mock()
    }

    return () => {
      // https://developers.intercom.com/installing-intercom/docs/intercom-javascript#intercomshutdown
      window.Intercom && window.Intercom('shutdown')
    }
  }, [])

  useEffect(() => {
    if (window.Intercom === undefined) {
      return
    }

    window.Intercom('boot', {
      app_id: APP_ID,
      email: user.email,
      user_id: user.id,
      company: isSuperUser(user)
        ? { id: '0', name: 'August Health' }
        : undefined,
      name: user.name && getFullName(user.name),
      custom_launcher_selector: `#${INTERCOM_LAUNCHER_ID}`,
      user_hash: user.intercomIdentity,
      role: userPrimaryRole(user),
    })
  }, [user.id])

  // We want the hook to fire whenever the current facility changes.
  const dependency =
    currentFacility?.tag === 'Complete' && currentFacility.value.id
  useEffect(() => {
    if (window.Intercom === undefined) {
      return
    }

    if (isSuperUser(user)) {
      // Super users are always associated with the 'August Health' company
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (currentFacility?.tag === 'Complete' && currentFacility.value) {
      window.Intercom('update', updateDataFor(user, currentFacility.value))
    }
  }, [dependency])

  return null
}

function updateDataFor(user: UserAccount, currentFacility: Facility) {
  if (hasResponsiblePersonRole(user)) {
    return {
      communityName: currentFacility.name,
    }
  }

  return {
    company: {
      id: currentFacility.id,
      name: currentFacility.name,
    },
  }
}
