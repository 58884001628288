import { AsyncIconButton } from '@shared/components/AsyncButton'
import { RequiredActionModal } from '@shared/components/baseMui/Modal/RequiredActionModal'
import { tw } from '@shared/utils/tailwind'

export function UpdateAppPopup() {
  return (
    <RequiredActionModal
      id={'update-app'}
      open={true}
      contentClassName={tw`absolute bottom-[32px] left-auto right-[32px] top-auto w-[423px] min-w-[423px] max-w-[423px] transform-none p-[24px] text-[14px] leading-[16px]`}
    >
      <div className={tw`flex`}>
        <div className={tw`mr-[16px]`}>
          <img
            className={tw`w-[55px]`}
            src="/svg/logo.svg"
            alt={'August Health Logo'}
          />
        </div>
        <div className={tw`vertical`}>
          <div className={tw`mb-[8px] font-bold`}>
            We've upgraded some features on this page!
          </div>
          <div className={tw`mb-[16px]`}>Please reload to continue.</div>
          <div>
            <AsyncIconButton
              initialIcon={'fa-sync-alt'}
              buttonStyle={'primary-fill'}
              onClick={() => window.location.reload()}
            >
              Reload Page
            </AsyncIconButton>
          </div>
        </div>
      </div>
    </RequiredActionModal>
  )
}
