import { UserAccount } from '@shared/types/user'
import { convertEnumValueToLabel } from '@shared/utils/common'
import {
  hasBillingRole,
  hasDirectorRole,
  hasFacilitySalesAndMarketingRole,
  hasFacilityStaffRole,
  hasFrontDeskRole,
  hasMedicalRole,
  hasMedTechRole,
  hasNurseRole,
  hasOrgAdminRole,
  hasResponsiblePersonRole,
  hasSocialWorkerRole,
  isAnalyst,
  isSuperUser,
  isToolsUser,
} from '@shared/utils/user'

export const userPrimaryRole = (user: UserAccount): string => {
  // return the role with the highest privileges as a string
  if (isSuperUser(user)) {
    return 'superUser'
  }
  if (isToolsUser(user)) {
    return 'advancedToolsUser'
  }
  if (isAnalyst(user)) {
    return 'basicToolsUser'
  }
  if (hasOrgAdminRole(user)) {
    return 'organizationAdmin'
  }
  if (hasDirectorRole(user)) {
    return 'facilityDirector'
  }
  if (hasFacilitySalesAndMarketingRole(user)) {
    return 'salesAndMarketing'
  }
  if (hasFacilityStaffRole(user)) {
    return 'staff'
  }
  if (hasMedicalRole(user)) {
    return 'medicalUser'
  }
  if (hasSocialWorkerRole(user)) {
    return 'socialWorker'
  }
  if (hasResponsiblePersonRole(user)) {
    return 'personRole' // Should we call it responsiblePersonRole?
  }
  if (hasBillingRole(user)) {
    return 'billingUser'
  }
  if (hasMedTechRole(user)) {
    return 'medTech'
  }
  if (hasFrontDeskRole(user)) {
    return 'frontDeskUser'
  }
  if (hasNurseRole(user)) {
    return 'nurse'
  }

  if (user.groups.length && user.groups[0].groupType) {
    return convertEnumValueToLabel(
      user.groups[0].groupType.replace('GROUP_TYPE_', '')
    )
  }

  return 'none'
}
