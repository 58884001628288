import { useTransitionTrigger } from '@mui/base/useTransition'
import { BaseModal, ModalProps } from '@shared/components/baseMui/Modal/Layout'
import { twx } from '@shared/utils/tailwind'

/**
 * Used to show errors or important notifications to the user
 * This modal does not allow closing via a background click or via a close button
 */
export const RequiredActionModal = ({
  id,
  contentClassName,
  backgroundClassName,
  open,
  children,
  ...rest
}: Omit<ModalProps, 'onClose'>) => {
  const backdropId = `${id}-required-action-modal-backdrop`
  const contentId = `${id}-required-action-modal-contents`
  const {
    contextValue: { requestedEnter, onExited },
  } = useTransitionTrigger(open)

  return (
    <BaseModal
      data-testid={id}
      id={backdropId}
      open={open}
      backgroundClassName={twx(
        'items-center justify-center z-[1500]',
        backgroundClassName
      )}
      requestedEnter={requestedEnter}
      onExited={onExited}
      {...rest}
    >
      <div
        id={contentId}
        className={twx(
          'relative h-fit max-h-[90vh] w-fit min-w-[30vw] max-w-[90vw] overflow-y-auto rounded-[8px] bg-background p-8',
          contentClassName
        )}
        role={'dialog'}
        aria-modal={true}
      >
        {children}
      </div>
    </BaseModal>
  )
}
