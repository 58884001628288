import { ReactNode } from 'react'
import {
  Controller,
  FieldPath,
  FieldValues,
  UseFormHandleSubmit,
  UseFormReturn,
} from 'react-hook-form'
import StyledSelect from '@shared/components/Selects/StyledSelect'
import {
  BloodPressureVitalsInput,
  VitalsInput,
  VitalsInputProps,
  WeightVitalsInput,
} from '@shared/components/Vitals/VitalInputs'
import { LatestVital } from '@shared/types/latest_vital'
import { Loading } from '@shared/utils/loading'
import { twx } from '@shared/utils/tailwind'
import {
  AllVitalTypes,
  ParameterizedVitalsType,
  VITALS_INPUT_PROPS_MAP,
  VitalsType,
} from '@shared/utils/vitals'

type VitalsSaveMode<Form extends FieldValues> =
  | {
      tag: 'onBlur'
      handleSubmit: UseFormHandleSubmit<Form>
      onSubmit: (formData: Form) => Promise<void>
    }
  | {
      tag: 'onClickSave'
    }

interface Props<Form extends FieldValues> {
  methods: UseFormReturn<Form>
  mode: VitalsSaveMode<Form>
  latestVitals: Loading<LatestVital>
  vitalsType: AllVitalTypes
  showTooltips?: boolean
  isRequired?: boolean
  disabled?: boolean
  className?: string
}

export default function VitalsInputProducer<Form extends FieldValues>({
  methods,
  mode,
  latestVitals,
  vitalsType,
  showTooltips = true,
  className = '',
  isRequired = false,
  disabled = false,
}: Props<Form>) {
  const {
    control,
    register,
    watch,
    setFocus,
    getValues,
    formState,
    getFieldState,
  } = methods
  const {
    iconClassName,
    name,
    label,
    unitsLabel,
    dropdownName,
    placeholder,
    options,
  } = VITALS_INPUT_PROPS_MAP<Form>(vitalsType)
  const submitOnBlur = mode.tag === 'onBlur'

  const isWeightInput = vitalsType === VitalsType.WEIGHT
  const isBloodPressureInput = vitalsType === VitalsType.BLOOD_PRESSURE

  const onBlur = () => {
    if (submitOnBlur) {
      void mode.handleSubmit(mode.onSubmit)()
    }
  }

  const { error, isDirty } = getFieldState(name as FieldPath<Form>, formState)
  const checkDiastolic = () => {
    if (isBloodPressureInput) {
      return !!getFieldState('diastolic' as FieldPath<Form>, formState).error
    }
    return false
  }

  const validate = (value: string) => {
    if (isDirty && Number(value) <= 0) {
      return `${name} has value less than or equal to 0`
    }
    return true
  }

  const vitalsInputContainerClasses = twx(
    'flex flex-1 items-center justify-between grow h-largeInput border border-form-input-border rounded-input p-inputPadding bg-white mb-[8px]',
    'hover:border-form-input-border-hover focus-within:border-form-input-border-hover focus-within:outline-double focus-within:outline-1 focus-within:outline-form-input-border-hover',
    {
      ['border-form-input-error-border hover:border-form-input-error-border focus-within:border-form-input-error-border focus-within:outline-form-input-error-border']:
        !!error || checkDiastolic(),
      'bg-form-input-disabled-background-color text-form-input-disabled-text-color cursor-default resize-none hover:border-form-input-border':
        disabled,
    }
  )

  const propsForInput: VitalsInputProps<Form> = {
    register,
    iconClassName,
    onBlur,
    watch,
    name: name as FieldPath<Form>,
    label,
    showTooltips,
    validate,
    isRequired,
    disabled,
  }

  const VitalsInputComponent = () => {
    if (isWeightInput) {
      return (
        <WeightVitalsInput
          {...propsForInput}
          getValues={getValues}
          latestVitals={latestVitals}
        />
      )
    } else if (isBloodPressureInput) {
      return (
        <BloodPressureVitalsInput
          {...propsForInput}
          diastolicName={'diastolic' as FieldPath<Form>}
        />
      )
    } else {
      return <VitalsInput {...propsForInput} />
    }
  }

  return (
    <div className={twx('flex justify-between gap-2', className)}>
      <VitalsInputContainer
        onClick={() => {
          if (!isBloodPressureInput) {
            setFocus(name as FieldPath<Form>)
          }
        }}
        unitsLabel={unitsLabel}
        className={twx(vitalsInputContainerClasses)}
        testId={`vitalsInputContainer-${name}`}
      >
        {VitalsInputComponent()}
      </VitalsInputContainer>
      {[
        VitalsType.BLOOD_SUGAR,
        VitalsType.OXYGEN_SATURATION,
        VitalsType.TEMPERATURE,
        VitalsType.BLOOD_PRESSURE,
        ParameterizedVitalsType.SYSTOLIC_BLOOD_PRESSURE,
        ParameterizedVitalsType.DIASTOLIC_BLOOD_PRESSURE,
      ].includes(vitalsType as VitalsType) && (
        <Controller
          control={control}
          name={dropdownName as FieldPath<Form>}
          render={({ field: { onChange, value } }) => (
            <>
              <label className={twx('visually-hidden')} htmlFor={dropdownName}>
                {dropdownName}
              </label>
              <StyledSelect
                name={dropdownName}
                size={'large'}
                inputId={dropdownName}
                value={value}
                onChange={onChange}
                blurInputOnSelect
                onBlur={onBlur}
                isDisabled={disabled}
                options={options}
                className={twx('max-w-[184px] flex-1 text-[4px]')}
                placeholder={placeholder}
                menuPlacement={
                  [
                    VitalsType.BLOOD_SUGAR,
                    VitalsType.OXYGEN_SATURATION,
                  ].includes(vitalsType as VitalsType)
                    ? 'top'
                    : 'auto'
                }
              />
            </>
          )}
        />
      )}
    </div>
  )
}

const VitalsInputContainer = (props: {
  children: ReactNode
  onClick: () => void
  unitsLabel: string
  className: string
  testId: string
}) => {
  const { children, onClick, unitsLabel, className, testId } = props
  return (
    <div className={twx(className)} onClick={onClick} data-testid={testId}>
      {children}
      <span
        className={twx(
          'ml-[8px] text-[14px] font-medium leading-[16px] text-gray-08'
        )}
      >
        {unitsLabel}
      </span>
    </div>
  )
}
