import { format, isEqual, parseISO } from 'date-fns'
import { sortBy } from 'lodash'
import { Link } from 'react-router-dom'
import { ParamMap } from 'urlcat'
import Icon from '@shared/components/Icon'
import Pill from '@shared/components/Pill'
import { EmarProgressApiPayload } from '@shared/types/emar'
import { tw, twx } from '@shared/utils/tailwind'

interface Props {
  title: string
  orgId: string
  facilityId: string
  activeDate: Date
  missedAdministrationsHistory: EmarProgressApiPayload['missedAdministrationsHistory']
  className?: string
  linkFunc: (orgId: string, facilityId: string, params: ParamMap) => string
}

export default function MissedAdministrationsBanner({
  title,
  orgId,
  facilityId,
  missedAdministrationsHistory,
  activeDate,
  className,
  linkFunc,
}: Props) {
  return missedAdministrationsHistory.length > 0 ? (
    <div
      className={twx(
        'flex flex-row items-center gap-3 overflow-y-auto rounded border-l-4 border-tags-red bg-white p-3',
        className
      )}
    >
      <span
        className={tw`whitespace-nowrap text-[14px] font-medium text-tags-red`}
      >
        <Icon name="square-exclamation" className={tw`mr-1 text-lg`} />
        {title}
      </span>
      <span
        className={tw`flex max-h-[72px] flex-wrap gap-2 overflow-hidden overflow-y-scroll`}
      >
        {sortedHistory(missedAdministrationsHistory).map(
          ({ date, parsedDate, count }) => (
            <LinkPill
              key={date}
              isActive={isEqual(activeDate, parsedDate)}
              to={linkFunc(orgId, facilityId, { date })}
            >
              {format(parsedDate, 'MMM d')} ({count})
            </LinkPill>
          )
        )}
      </span>
    </div>
  ) : null
}

interface LinkPillProps {
  isActive: boolean
  to: string
  children: React.ReactNode
  key: React.Key
}

const LinkPill = ({ isActive, to, children }: LinkPillProps) => (
  <Link aria-current={isActive ? 'true' : undefined} to={to}>
    <Pill
      shape="round"
      className={twx('bg-bg-overdue px-2 normal-case text-overdue', {
        'border-tags-red': isActive,
        'border-bg-overdue': !isActive,
      })}
    >
      {children}
    </Pill>
  </Link>
)

const sortedHistory = (
  missedAdministrationsHistory: Props['missedAdministrationsHistory']
) => {
  return sortBy(
    missedAdministrationsHistory.map((entry) => ({
      ...entry,
      parsedDate: parseISO(entry.date),
    })),
    'date'
  ).reverse()
}
