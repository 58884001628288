import React, { ElementType, HTMLProps } from 'react'
import { Link } from 'react-router-dom'
import {
  ButtonSize,
  ButtonStyle,
  ButtonType,
  DataAttributeKey,
  sharedButtonClassLists,
} from '@shared/components/AsyncButton'
import { BasicSpinner } from '@shared/components/BasicSpinner'
import Icon from '@shared/components/Icon'
import { twx } from '@shared/utils/tailwind'

export interface Props extends HTMLProps<HTMLAnchorElement> {
  isLoading?: boolean
  icon?: string
  href: string
  buttonStyle: ButtonStyle
  buttonSize?: ButtonSize
  type?: ButtonType

  [dataAttribute: DataAttributeKey]: string
}

export default function LinkButton({
  buttonSize = 'medium',
  className,
  disabled,
  buttonStyle,
  isLoading,
  icon,
  children,
  href,
  ...rest
}: Props) {
  const AnchorTag = (
    href.match(/^(?:[a-z+]+:)?\//)
      ? Link
      : ('a' as React.JSX.IntrinsicElements['a'])
  ) as ElementType

  return (
    <AnchorTag
      className={twx(
        'inline-flex items-baseline justify-center uppercase no-underline',
        ...sharedButtonClassLists(buttonStyle, buttonSize),
        {
          'cursor-not-allowed opacity-50': disabled,
        },
        className
      )}
      href={disabled ? '' : href}
      to={disabled ? '' : href}
      onClick={(e: Event) => {
        if (disabled) {
          e.preventDefault()
          return false
        }

        return true
      }}
      {...rest}
    >
      {isLoading && (
        <BasicSpinner
          className={twx('fa-fw', {
            'mr-[6px]': buttonSize === 'small' || buttonSize === 'xsmall',
            'mr-[8px]': buttonSize === 'medium',
            'mr-[10px]': buttonSize === 'xlarge',
          })}
        />
      )}
      {icon && (
        <Icon
          name={icon}
          className={twx({
            'mr-[6px]': buttonSize === 'small' || buttonSize === 'xsmall',
            'mr-[8px]': buttonSize === 'medium',
            'mr-[10px]': buttonSize === 'xlarge',
          })}
        />
      )}
      <span>{children}</span>
    </AnchorTag>
  )
}
