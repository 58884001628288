import crypto from 'crypto'

export function getMockAuthToken(username: string) {
  const cognitoUserKey = 'cognito:username'
  const mockJwtSecret = 'testKey'
  // Set headers for JWT
  const header = {
    kid: 'my_test_key',
    typ: 'JWT',
    alg: 'HS256',
  }

  // Convert the current time to seconds
  const currentTimestamp = Math.floor(Date.now() / 1000)

  const data = {
    iss: '',
    ist: '',
    iat: currentTimestamp,
    exp: currentTimestamp + 30, // expiry time is 30 seconds from time of creation
    jti: 'jwt_nonce',
    sub: '1',
    [cognitoUserKey]: username,
  }

  const encodedHeader = base64url(JSON.stringify(header))
  const encodedData = base64url(JSON.stringify(data))
  const token = `${encodedHeader}.${encodedData}`
  const signature = toUrl(
    crypto.createHmac('sha256', mockJwtSecret).update(token).digest('base64')
  )
  return `${token}.${signature}`
}

function base64url(source: string): string {
  const buff = Buffer.from(source)
  return toUrl(buff.toString('base64'))
}

function toUrl(source: string): string {
  // JWT requires base64ulr which removes padding characters and replaces + and / with - and _
  return source.replace(/=+$/, '').replace(/\+/g, '-').replace(/\//g, '_')
}
