import { twx } from '@shared/utils/tailwind'

interface Props extends React.HTMLProps<HTMLButtonElement> {
  icon?: string
  iconClasses?: string
  linkStyle?: 'primary' | 'secondary'
}

export default function ButtonLink(props: Props) {
  const {
    children,
    className,
    onClick,
    type,
    icon,
    iconClasses,
    linkStyle = 'primary',
    ...rest
  } = props

  return (
    <button
      type={'button'}
      className={twx(
        'm-0 p-0 font-medium',
        'cursor-pointer whitespace-nowrap border-0 bg-transparent',
        {
          'text-august-primary hover:text-primary-light focus:text-primary-light disabled:cursor-default disabled:text-gray-08':
            linkStyle === 'primary',
          'font-semibold text-white hover:text-gray-10 focus:text-gray-10 disabled:cursor-default disabled:text-banner':
            linkStyle === 'secondary',
        },
        className
      )}
      {...rest}
      onClick={onClick}
    >
      {icon && <i className={twx('fa fa-fw mr-[6px]', icon, iconClasses)} />}
      {children}
    </button>
  )
}
