import { PillShape, PillSize } from '@shared/components/Pill'
import { MedicationOrder } from '@shared/types/medication_order'
import {
  getDeaSchedule,
  isAfterMeal,
  isBeforeMeal,
  isNarcotic,
  isOnlyPRN,
  isPsychotropic,
  isSelfAdministered,
  isTreatment,
  isVitalOrder,
} from '@shared/utils/medicationStatement'
import { twx } from '@shared/utils/tailwind'
import { MedicationAdministrationRow } from '@emar/types/db'
import { MedOrderPill } from './MedOrderPill'

type Props = {
  order: MedicationOrder
  administration?: MedicationAdministrationRow
  pillClassName?: string
  pillShape?: PillShape
  pillSize?: PillSize
  showTreatment?: boolean
  administrationPrnOverride?: 'use-order' | 'is-prn-part' | 'is-routine-part'
}
export default function MedOrderPills({
  order,
  administration,
  pillClassName,
  pillShape,
  pillSize,
  showTreatment = true,
  administrationPrnOverride = 'use-order',
}: Props) {
  const statement = order.medicationStatement

  const deaSchedule = getDeaSchedule(statement)
  const shouldShowPrnPill =
    administrationPrnOverride === 'is-prn-part' ||
    (administrationPrnOverride === 'use-order' && isOnlyPRN(statement))

  return (
    <>
      {isVitalOrder(order) && !administration && (
        <MedOrderPill
          size={pillSize}
          shape={pillShape}
          type="vital"
          className={twx(pillClassName)}
        />
      )}
      {shouldShowPrnPill && (
        <MedOrderPill
          size={pillSize}
          shape={pillShape}
          type="prn"
          className={twx(pillClassName)}
        />
      )}
      {isPsychotropic(statement) && (
        <MedOrderPill
          size={pillSize}
          shape={pillShape}
          type="psychotropic"
          className={twx(pillClassName)}
        />
      )}
      {isNarcotic(statement) && (
        <MedOrderPill
          size={pillSize}
          shape={pillShape}
          type="narcotic"
          className={twx(pillClassName)}
        />
      )}
      {!!deaSchedule && (
        <MedOrderPill
          size={pillSize}
          shape={pillShape}
          type={deaSchedule}
          className={twx(pillClassName)}
        />
      )}
      {showTreatment && isTreatment(statement) && (
        <MedOrderPill
          size={pillSize}
          shape={pillShape}
          type="treatment"
          className={twx(pillClassName)}
        />
      )}
      {isSelfAdministered(statement) && (
        <MedOrderPill
          size={pillSize}
          shape={pillShape}
          type={'self-administered'}
        />
      )}
      {isBeforeMeal(statement) && (
        <MedOrderPill
          size={pillSize}
          shape={pillShape}
          type="before meal"
          className={twx(pillClassName)}
        />
      )}
      {isAfterMeal(statement) && (
        <MedOrderPill
          size={pillSize}
          shape={pillShape}
          type="after meal"
          className={twx(pillClassName)}
        />
      )}
    </>
  )
}
