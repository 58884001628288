import { ReactNode } from 'react'
import { twx } from '@shared/utils/tailwind'

type DataAttributeKey = `data-${string}`
export type PillShape = 'round' | 'rectangle' | 'right-arrow'
export type PillSize = 'small' | 'medium'

type Props = {
  className?: string
  children: ReactNode
  shape?: PillShape
  size?: PillSize
  [dataAttribute: DataAttributeKey]: string
}

export default function Pill({
  children,
  className,
  shape = 'round',
  size = 'small',
  ...rest
}: Props) {
  return (
    <div
      className={twx(
        'flex-0 relative h-min max-h-min min-h-min w-fit place-self-start self-start whitespace-nowrap border border-transparent bg-black font-medium uppercase leading-3 text-white',
        {
          'rounded-l-[3px] py-[2px]': shape === 'right-arrow',
          'right-arrow mr-2 rounded-r-[4px]':
            shape === 'right-arrow' && size === 'medium',
          'right-arrow-small mr-[6px] rounded-r-[3px]':
            shape === 'right-arrow' && size === 'small',
          'rounded-full': shape === 'round',
          'rounded-[3px]': shape === 'rectangle',
        },
        {
          'px-[4px] py-[2px] text-[10px] leading-[12px]': size === 'small',
          'px-[6px] py-[4px] text-[13px] leading-[12px]': size === 'medium',
        },
        className
      )}
      {...rest}
    >
      {children}
    </div>
  )
}
