import Badge from '@shared/components/Badge'
import { Person } from '@shared/types/person'
import { PickPartial } from '@shared/types/utilities'
import { AmbulatoryBadgeColor } from '@shared/utils/ambulatoryStatus'
import {
  displayCPRCode,
  getOnHospice,
  hasDementia,
  isRespite,
} from '@shared/utils/person'
import { tw, twx } from '@shared/utils/tailwind'

export default function PersonPills({
  person,
  containerClass,
  ambulatoryBadgeColor,
  ambulatoryStatus,
  className,
}: {
  person: PickPartial<
    Person,
    'cprCode' | 'ambulatoryStatus' | 'conditions' | 'respiteStatus'
  >
  containerClass?: string
  ambulatoryBadgeColor: AmbulatoryBadgeColor
  ambulatoryStatus: string
  className?: string
}) {
  const cprText = displayCPRCode(person)
  let cprColor: 'gray' | 'green' | 'darkOrange' = 'gray'

  if (cprText === 'DNR') {
    cprColor = 'darkOrange'
  } else if (cprText === 'Full Code') {
    cprColor = 'green'
  }

  const classes = twx('gap-y-2', containerClass, {
    ['flex flex-row']: !containerClass,
  })
  return (
    <div className={twx(classes, className)}>
      {cprText && (
        <Badge
          color={cprColor}
          shape="square"
          className={tw`mr-[8px]`}
          id="header-cpr-badge-text"
        >
          {cprText}
        </Badge>
      )}
      {person.ambulatoryStatus?.ambulatoryResult && (
        <Badge
          className={tw`mr-[8px]`}
          color={ambulatoryBadgeColor}
          shape="square"
        >
          {ambulatoryStatus}
        </Badge>
      )}
      {hasDementia(person) && (
        <Badge className={tw`mr-[8px]`} color={'orange'} shape="square">
          Dementia
        </Badge>
      )}
      {getOnHospice(person) && (
        <Badge className={tw`mr-[8px]`} color={'orange'} shape="square">
          Hospice
        </Badge>
      )}
      {isRespite(person) && (
        <Badge className={tw`mr-[8px]`} color={'green'} shape="square">
          Respite
        </Badge>
      )}
    </div>
  )
}
