// eslint-disable-next-line check-file/filename-naming-convention
import { CSSProperties, PropsWithChildren, ReactNode } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import Card from '@shared/components/Card'
import { twx } from '@shared/utils/tailwind'

interface LinkCardProps extends PropsWithChildren {
  id?: string
  style?: CSSProperties
  className?: string
  anchorClassName?: string
  href?: string
  onClick?: () => void
  LinkComponent?: (
    props: LinkProps & React.RefAttributes<HTMLAnchorElement>
  ) => ReactNode
}

export function LinkCard({
  href,
  children,
  className,
  anchorClassName,
  onClick,
  LinkComponent = Link,
  ...rest
}: LinkCardProps) {
  const card = <Card className={twx(className)}>{children}</Card>

  if (href) {
    return (
      <LinkComponent
        to={href}
        className={twx('block no-underline', anchorClassName)}
        onClick={onClick}
        {...rest}
      >
        {card}
      </LinkComponent>
    )
  }

  // falsy herf will make card read-only, act like disable without disable styles
  return card
}
