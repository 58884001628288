import React, { Dispatch, SetStateAction } from 'react'

export interface SidebarContext {
  isAnyMenuOpen: boolean
  setIsAnyMenuOpen: Dispatch<SetStateAction<boolean>>
  showMobileSidebar: boolean
  setShowMobileSidebar: Dispatch<SetStateAction<boolean>>
}

export default React.createContext<SidebarContext>({
  isAnyMenuOpen: false,
  setIsAnyMenuOpen: () => {},
  showMobileSidebar: false,
  setShowMobileSidebar: () => {},
})
