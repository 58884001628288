export const browserSupportsIndexedDb = async () => {
  try {
    return (
      // Check key presence; avoids SecurityError
      'indexedDB' in globalThis &&
      // Check value presence; potentially triggers SecurityError
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      globalThis.indexedDB !== undefined &&
      // Actually try to connect
      (await canConnectToIndexedDB())
    )
  } catch (error: unknown) {
    console.error(
      'eMAR is unavailable: Browser does not support IndexedDB',
      error
    )
    return false
  }
}

const canConnectToIndexedDB = async (): Promise<boolean> => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!window) {
    return Promise.resolve(false)
  } else if (window.browserHasIndexedDbSupport !== undefined) {
    return Promise.resolve(window.browserHasIndexedDbSupport)
  } else {
    return new Promise(function (resolve, reject) {
      const request = indexedDB.open('emar_feature_sniff')

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (request) {
        request.onsuccess = () => {
          window.browserHasIndexedDbSupport = true
          resolve(true)
        }
        request.onerror = (error) => {
          // Firefox in Private browsing mode will feature-sniff IndexedDB as available
          // but will fail to open the database (https://bugzilla.mozilla.org/show_bug.cgi?id=781982)
          reject(error)
        }
      } else {
        // Safari on iOS can return `null` from `indexedDB.open()`
        // https://stackoverflow.com/questions/27415998/indexeddb-open-returns-null-on-safari-ios-8-1-1-and-halts-execution-on-cordova
        reject('IndexedDB open request returned nullish value')
      }
    })
  }
}
