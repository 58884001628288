import { twx } from '@shared/utils/tailwind'

export type VerticalBarColors =
  | 'primary-light'
  | 'tags-red'
  | 'tags-blue'
  | 'pill-warning'
  | 'mute'
  | 'alert'
  | 'tags-happy'

const colorToClassName: Record<VerticalBarColors, string> = {
  'primary-light': 'after:bg-primary-light',
  'tags-red': 'after:bg-tags-red',
  'tags-blue': 'after:bg-tags-blue',
  'pill-warning': 'after:bg-pill-warning',
  mute: 'after:bg-gray-12',
  alert: 'after:bg-alert',
  'tags-happy': 'after:bg-tags-happy',
}
export const VerticalRoundedBar = ({
  color = 'primary-light',
  disabled = false,
  className,
  roundedTop = true,
  roundedBottom = true,
}: {
  color?: VerticalBarColors
  disabled?: boolean
  className?: string
  roundedTop?: boolean
  roundedBottom?: boolean
}) => {
  const calculatedColor: string = colorToClassName[color]

  const className_ = twx(
    "after:content-[' '] after:block after:h-full after:w-[2px]",
    {
      [calculatedColor]: calculatedColor && !disabled,
      [`after:bg-secondary-07`]: disabled,
      'after:rounded-t-full': roundedTop,
      'after:rounded-b-full': roundedBottom,
    },
    className
  )

  return <div aria-hidden={true} className={twx(className_)} />
}
