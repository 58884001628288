import { ForwardedRef, forwardRef } from 'react'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { SquareBadge } from '@shared/components/SquareBadge'
import { GenericTooltip } from '@shared/components/Tooltips/GenericTooltip'
import { tw, twx } from '@shared/utils/tailwind'

type Props = React.HTMLProps<HTMLInputElement> & {
  ref?: ForwardedRef<HTMLInputElement>
  badgeLabel: string
  staticInputText?: string
  showErrorBorder?: boolean
  disabled?: boolean
  name?: string
  className?: string
  inputSize?: 'large' | 'default'
  tooltip?: string
}

export const BadgeInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    badgeLabel,
    className,
    staticInputText,
    showErrorBorder = false,
    disabled = false,
    tooltip,
    ...rest
  } = props

  const containerClasses = twx(
    'flex items-center flex-1 h-input border border-form-input-border rounded-input p-inputPadding bg-white hover:border-form-input-border-hover focus-within:border-form-input-border-hover focus-within:outline-double focus-within:outline-1 focus-within:outline-form-input-border-hover',
    {
      'border-form-input-error-border hover:border-form-input-error-border hover:outline-form-input-error-border focus-within:border-form-input-error-border focus-within:outline-form-input-error-border':
        showErrorBorder,
      'bg-form-input-disabled-background-color text-form-input-disabled-text-color hover:outline-none hover:border-form-input-border cursor-default':
        disabled,
      'h-largeInput': props.inputSize === 'large',
    },
    className
  )

  const badgeClasses = twx(
    'h-[18px] py-[3px] px-[4px] text-[12px] leading-[12px] whitespace-nowrap flex-0',
    {
      'bg-gray-07': disabled,
      'bg-form-alert': showErrorBorder,
    }
  )

  const twInputClasses = twx(
    'bg-transparent border-0 border-transparent border-left-0 border-right-0 border-top-1 border-bottom-1 p-0 outline-none w-full rounded-l-0 rounded-r-input enabled:hover:outline-none enabled:focus:outline-none enabled:focus-visible:outline-none enabled:active:outline-none disabled:border-form-input-border disabled:cursor-default disabled:bg-transparent',
    {
      'rounded-0': staticInputText,
      'border-form-input-error-border': showErrorBorder,
    }
  )

  return (
    <div className={tw`${containerClasses}`}>
      <label
        htmlFor={props.name}
        className={twx(
          'mx-0 my-[4px] inline-flex w-fit items-center justify-center'
        )}
      >
        <SquareBadge className={tw`${badgeClasses}`}>{badgeLabel}</SquareBadge>
      </label>
      <div className={tw`relative flex-1`}>
        {tooltip && (
          <GenericTooltip
            className={tw`absolute bottom-input w-fit whitespace-nowrap hover:opacity-80`}
          >
            {tooltip}
          </GenericTooltip>
        )}
        <BasicInput
          containerClassName={tw`static w-full`}
          className={tw`${twInputClasses}`}
          ref={ref}
          size={4}
          disabled={disabled}
          id={props.name}
          {...rest}
        />
      </div>
      {staticInputText && (
        <span className={tw`ml-[4px] block w-fit text-formInput font-medium`}>
          {staticInputText}
        </span>
      )}
    </div>
  )
})

BadgeInput.displayName = 'BadgeInput'
