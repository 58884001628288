/* Credits: usehooks npm package
 * link: https://usehooks.com/useLocalStorage/
 */

/* TODO Refactor day idea: replace other inline localStorage writes / reads
 * with this hook
 */
import localForage from 'localforage'
import { useEffect, useState } from 'react'
import ErrorMonitoring from '@shared/ErrorMonitoring'
import { AugustError } from '@shared/utils/error'
import { AsyncResult, loaded, LOADING } from '@shared/utils/loading'

export default function useLocalStorage<T>(
  key: string,
  initialValue: T
): [AsyncResult<T, AugustError>, (value: T | ((val: T) => T)) => void] {
  const [storedValue, setStoredValue] =
    useState<AsyncResult<T, AugustError>>(LOADING)

  useEffect(() => {
    try {
      void localForage.getItem<string>(key).then((item) => {
        setStoredValue(loaded(item ? (JSON.parse(item) as T) : initialValue))
      })
    } catch (error) {
      ErrorMonitoring.capture({ error, level: 'warning' })
      setStoredValue(loaded(initialValue))
    }
  }, [])

  const setValue = (value: T | ((val: T) => T)) => {
    setStoredValue(LOADING)
    const valueToStore =
      value instanceof Function ? value(storedValue as T) : value
    try {
      void localForage.setItem(key, JSON.stringify(valueToStore)).then(() => {
        setStoredValue(loaded(valueToStore))
      })
    } catch (error) {
      ErrorMonitoring.capture({ error, level: 'warning' })
      setStoredValue(loaded(valueToStore))
    }
  }

  return [storedValue, setValue]
}
