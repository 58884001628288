export const CHART_COLORS = {
  LABEL: {
    SUCCESS: '#7AC74D',
    WARNING: '#F5B400',
    DANGER: '#E9354A',
    INFO: '#7141b9',
    MUTED: '#D8D8D8',
  },
  BAR_FILL: {
    SUCCESS: '#CDEBBC',
    WARNING: '#FFF7E0',
    DANGER: '#FEECED',
    INFO: '#DFD6EF',
    MUTED: '#F2F2F2',
  },
} as const
