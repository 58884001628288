import { Link } from 'react-router-dom'
import { tw, twx } from '@shared/utils/tailwind'

const NavigationItem = ({
  isActive,
  url,
  count,
  title,
  titleComponent,
  iconClass,
}: {
  isActive: boolean
  url: string
  count?: number | string
  title: string
  titleComponent?: React.ReactNode
  iconClass?: string
}) => (
  <li
    data-testid={title}
    className={twx('transition-all hover:bg-scrim hover:font-medium', {
      'bg-scrim font-medium': isActive,
    })}
  >
    <Link
      to={url}
      className={tw`flex h-full w-full items-center justify-between py-[12px] pl-[24px] pr-[32px] text-[14px] text-white no-underline`}
    >
      <div
        className={tw`flex items-center text-[14px] font-medium leading-[16px]`}
      >
        <span className={tw`visually-hidden`}>{title}</span>
        {iconClass && (
          <i
            aria-hidden={true}
            className={tw`${iconClass} mr-[8px] inline-block w-[16px] text-center text-[12px] text-white`}
          />
        )}
        {titleComponent || title}
      </div>
      {count !== undefined && (
        <div
          data-testid={`${title}-count`}
          className={tw`w-[16px] text-center font-medium text-banner`}
        >
          {count}
        </div>
      )}
    </Link>
  </li>
)

export default NavigationItem
