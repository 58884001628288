import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/base/Button'
import React from 'react'
import {
  ButtonSize,
  ButtonStyle,
  sharedButtonClassLists,
} from '@shared/components/AsyncButton'
import { BasicSpinner } from '@shared/components/BasicSpinner'
import { twx } from '@shared/utils/tailwind'

export type ButtonProps = MuiButtonProps & {
  buttonStyle?: ButtonStyle
  buttonSize?: ButtonSize
  isLoading?: boolean
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      buttonStyle = 'primary-fill',
      buttonSize = 'medium',
      children,
      className,
      isLoading = false,
      disabled,
      ...rest
    } = props
    return (
      <MuiButton
        {...rest}
        ref={ref}
        className={twx(
          'inline-flex items-center justify-center uppercase',
          ...sharedButtonClassLists(buttonStyle, buttonSize),
          className
        )}
        disabled={disabled || isLoading}
        {...(rest.id && { ['data-testid']: rest.id })}
      >
        {isLoading && (
          <BasicSpinner
            className={twx('fa-fw', {
              'mr-[6px]': buttonSize === 'small' || buttonSize === 'xsmall',
              'mr-[8px]': buttonSize === 'medium',
              'mr-[10px]': buttonSize === 'xlarge',
            })}
          />
        )}

        {children}
      </MuiButton>
    )
  }
)

Button.displayName = 'Button'
