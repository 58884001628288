import { Parameter } from '@augusthealth/models/com/august/protos/dosage'

export function validParameterOrNull(parameter: Parameter): Parameter | null {
  if (
    parameter.parameterType &&
    parameter.conditional &&
    parameter.numeric?.value
  ) {
    return parameter
  }

  return null
}
