import localForage from 'localforage'
import * as memoryDriver from 'localforage-driver-memory'
import { browserSupportsIndexedDb } from '@shared/utils/indexedDB'
import 'core-js/full/observable'

export const configureLocalStorage = async () => {
  await localForage.defineDriver(memoryDriver).then(async () => {
    localForage.config({
      driver: await getSupportedDriver(),
      name: 'august_health',
      version: 1.0,
      storeName: 'august_general',
    })
    await localForage.ready()
  })
}

export const browserSupportsLocalStorage = () => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!window) {
    return false
  } else if (window.browserHasLocalStorageSupport !== undefined) {
    return window.browserHasLocalStorageSupport
  } else {
    try {
      // The `'localStorage' in window` check is necessary` to avoid a `SecurityError` if the feature
      // is disallowed (as opposed to `window.localStorage` which would raise a `SecurityError`)
      const localStoragePresent = Boolean(
        // eslint-disable-next-line no-restricted-syntax
        'localStorage' in window && window.localStorage
      )
      // Actually try to write something to verify
      // eslint-disable-next-line no-restricted-syntax
      window.localStorage.setItem('feature_sniff_test', 'test')
      window.browserHasLocalStorageSupport = localStoragePresent
    } catch (error: unknown) {
      window.browserHasLocalStorageSupport = false
    }
    return window.browserHasLocalStorageSupport
  }
}

const browserSupportsWebSql = () => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!window) {
    return false
  } else if (window.browserHasWebSqlSupport !== undefined) {
    return window.browserHasWebSqlSupport
  } else {
    try {
      window.browserHasWebSqlSupport = Boolean(
        'openDatabase' in window && window.openDatabase
      )
    } catch (error: unknown) {
      window.browserHasWebSqlSupport = false
    }
    return window.browserHasWebSqlSupport
  }
}

const getSupportedDriver = async () => {
  if (browserSupportsLocalStorage()) {
    return localForage.LOCALSTORAGE
  }

  if (await browserSupportsIndexedDb()) {
    return localForage.INDEXEDDB
  }

  if (browserSupportsWebSql()) {
    return localForage.WEBSQL
  }

  return memoryDriver._driver
}
