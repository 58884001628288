import { GenericTooltip } from 'shared/components/Tooltips/GenericTooltip'
import Icon from '@shared/components/Icon'
import { twx } from '@shared/utils/tailwind'

export function AlertTooltip({
  onClose,
  className = '',
  caretPosition = 'bottom',
}: {
  onClose: () => void
  className?: string
  caretPosition?: 'top' | 'bottom'
}) {
  const classes = twx('w-[216px]', className)
  return (
    <GenericTooltip
      data-testid="weight-alert"
      className={twx(classes)}
      caretPosition={caretPosition}
    >
      <div className={twx('mb-[12px] flex flex-row items-center gap-1')}>
        <Icon name={'triangle-exclamation'} className={twx('text-white')} />
        Significant change!
      </div>
      Please double-check and consult a doctor as needed.
      <button
        data-testid="weight-alert-close"
        onClick={() => onClose()}
        type="button"
        className={twx(
          'mt-[12px] rounded-[4px] border border-white bg-white px-[10px] py-[6px] text-[12px] font-semibold leading-[12px] text-form-alert'
        )}
      >
        OK
      </button>
    </GenericTooltip>
  )
}
