import { twx } from '@shared/utils/tailwind'

export type IconVariant = 'solid' | 'regular' | 'light' | 'duotone' | 'thin'
export type IconSize = '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'

type Props = React.HTMLProps<HTMLElement> & {
  className?: string
  name: string
  variant?: IconVariant
  iconSize?: IconSize
}

export default function Icon({
  className,
  name,
  variant = 'solid',
  iconSize = 'md',
  ...rest
}: Props) {
  return (
    <i
      className={twx(
        className,
        `fa-fw fa-${name.replace('fa-', '')}`,
        {
          'fa-solid': variant === 'solid',
          'fa-regular': variant === 'regular',
          'fa-light': variant === 'light',
          'fa-duotone': variant === 'duotone',
          'fa-thin': variant === 'thin',
        },
        iconSize === 'md' ? '' : `fa-${iconSize}`
      )}
      aria-hidden="true"
      {...rest}
    />
  )
}
