// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import clsx, { ClassValue } from 'clsx'
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { twMerge } from 'tailwind-merge'

const identity = (
  strings: TemplateStringsArray,
  ...values: Array<unknown>
): string => String.raw({ raw: strings }, ...values)

export const tw = (
  strings: TemplateStringsArray,
  ...values: Array<unknown>
): string => twMerge(clsx(identity(strings, ...values)))

export const twx = (...classLists: ClassValue[]): string =>
  twMerge(clsx(...classLists))

// Supported animation delays - do not delete
// Static class names are detected by tailwind at build time:
// https://tailwindcss.com/docs/content-configuration#class-detection-in-depth
// motion-safe:animation-delay-[0ms] motion-safe:animation-delay-[50ms]
// motion-safe:animation-delay-[150ms] motion-safe:animation-delay-[100ms]
// motion-safe:animation-delay-[200ms] motion-safe:animation-delay-[250ms]
