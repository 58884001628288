import { BloodSugarTiming } from '@augusthealth/models/com/august/protos/vital'
import { differenceInCalendarDays } from 'date-fns'
import { FormState } from 'react-hook-form'
import { LatestVital } from '@shared/types/latest_vital'
import { VitalPayload } from '@shared/types/vital'
import {
  validNumberOrNull,
  validStringOrUndefined,
} from '@shared/utils/parsing'
import { fromTimeStringToTime } from '@shared/utils/time'
import { Units, VitalsFormData } from '@shared/utils/vitals'

const isOutsideRange = (
  a: number,
  b: number | undefined,
  tolerance: number
) => {
  if (!b) return false
  const diff = a - b
  return Math.abs(diff) >= tolerance || Math.abs(diff / 100) >= tolerance / 100
}

export const hasWeightSignificantlyChanged = ({
  weight,
  date,
  oldData,
}: {
  weight: string
  date: Date | null
  oldData: LatestVital
}) => {
  const enteredWeight = +weight
  const formDate = date as Date

  const mostRecentWeightDateString = oldData.compositePanel?.weight?.recordedAt
  const mostRecentWeightValue = oldData.compositePanel?.weight?.record?.value

  if (
    mostRecentWeightDateString === undefined ||
    mostRecentWeightValue === undefined
  ) {
    return false
  }

  const mostRecentWeightDate = new Date(mostRecentWeightDateString)
  const diffDays = differenceInCalendarDays(formDate, mostRecentWeightDate)

  if (90 < diffDays && diffDays <= 180) {
    return isOutsideRange(mostRecentWeightValue, enteredWeight, 10)
  } else if (30 < diffDays && diffDays <= 90) {
    return isOutsideRange(mostRecentWeightValue, enteredWeight, 7.5)
  } else if (7 < diffDays && diffDays <= 30) {
    return isOutsideRange(mostRecentWeightValue, enteredWeight, 5)
  } else if (1 < diffDays && diffDays <= 7) {
    return isOutsideRange(mostRecentWeightValue, enteredWeight, 3)
  } else if (diffDays <= 1) {
    return isOutsideRange(mostRecentWeightValue, enteredWeight, 2)
  }

  return false
}

export function formDataToVitalsPayload(data: VitalsFormData): VitalPayload {
  const time = data.time ? fromTimeStringToTime(data.time) : null
  const date = data.date as Date
  if (time) {
    time.hour !== undefined && date.setHours(time.hour)
    time.minute !== undefined && date.setMinutes(time.minute)
  }
  const vitals: VitalPayload = {
    recordedAt: date.toISOString(),
    panel: {
      weight: data.weight
        ? { value: +data.weight, unit: Units.lbs }
        : undefined,
      temperature: data.temperature
        ? {
            value: +data.temperature,
            type: data.temperatureType?.value,
            unit: Units.degF,
          }
        : undefined,
      bloodPressure: data.systolic
        ? {
            systolic: { value: +data.systolic, unit: Units.mmHg },
            diastolic: { value: +data.diastolic, unit: Units.mmHg },
            position: data.bloodPressurePosition?.value,
          }
        : undefined,
      heartRate: data.heartRate
        ? { value: +data.heartRate, unit: Units.bpm }
        : undefined,
      respiratoryRate: data.respiratoryRate
        ? { value: +data.respiratoryRate, unit: Units.brpm }
        : undefined,
      oxygenSaturation: data.oxygenSaturation
        ? {
            value: +data.oxygenSaturation,
            supplemental: data.supplementalOxygen?.value,
            unit: Units.o2,
          }
        : undefined,
      bloodSugar: data.bloodSugar
        ? {
            value: +data.bloodSugar,
            timing:
              data.bloodSugarTiming?.value ??
              (validNumberOrNull(data.bloodSugar)
                ? BloodSugarTiming.BLOOD_SUGAR_TIMING_UNSPECIFIED
                : undefined),
            unit: Units.mgdL,
          }
        : undefined,
    },
    incidentNote: validStringOrUndefined(data.incidentNote),
  }
  return vitals
}

export const doesFormHaveErrors = (formState: FormState<VitalsFormData>) => {
  const errors = formState.errors
  return Object.keys(errors).length !== 0
}
