import {
  PasswordPolicy,
  SecurityPolicySettings,
} from '@shared/types/security_policy_settings'

export function isStrictPasswordPolicy(
  settings: SecurityPolicySettings
): boolean {
  return (
    settings.settings.securityPolicy.passwordPolicy ===
    PasswordPolicy.PASSWORD_POLICY_STRICT
  )
}
