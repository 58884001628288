import { twx } from '@shared/utils/tailwind'

export function SquareBadge(props: React.HTMLProps<HTMLSpanElement>) {
  const { className, children, ...rest } = props

  return (
    <span
      className={twx(
        'inline-block rounded-[4px] bg-primary-light px-[8px] py-[4px] text-[14px] font-semibold uppercase text-white',
        className
      )}
      {...rest}
    >
      {children}
    </span>
  )
}
