import { clearCareAppUserAccountCache } from '@careapp/cache'
import { ReactNode, useEffect, useState } from 'react'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import {
  getAuthenticatedUser,
  logoutIfNoSessionIsStored,
} from '@shared/components/Auth/helpers'
import { useUserContext } from '@shared/contexts/UserContext'
import environment from '@shared/environment'
import ErrorMonitoring from '@shared/ErrorMonitoring'
import { UserAccount } from '@shared/types/user'
import { clearEmarRuntimeCache } from '@shared/utils/emar'
import { tw } from '@shared/utils/tailwind'

interface Props {
  children: ReactNode
}

export const UserSessionSynchronizer = ({ children }: Props) => {
  const [userSessionInSync, setUserSessionInSync] = useState(true)
  const { user } = useUserContext()
  const setAsOutOfSync = () => setUserSessionInSync(false)

  function syncSessionState(e: StorageEvent) {
    logoutIfNoSessionIsStored(e)
    void ensureUserMatches({ user, setAsOutOfSync })
  }

  useEffect(() => {
    void ensureUserMatches({ user, setAsOutOfSync })
    window.addEventListener('storage', syncSessionState)
    return () => window.removeEventListener('storage', syncSessionState)
  }, [user])

  if (userSessionInSync || environment.isCypress) {
    return children
  } else {
    return (
      <AnimatedPopup
        title={null}
        containerClassName={tw`absolute bottom-[32px] left-auto right-[32px] top-auto w-[423px] transform-none p-[24px] text-[14px] leading-[16px]`}
      >
        <div className={tw`flex`}>
          <div className={tw`mr-[16px]`}>
            <img
              className={tw`w-[55px]`}
              src="/svg/logo.svg"
              alt={'August Health Logo'}
            />
          </div>
          <div className={tw`vertical`}>
            <div className={tw`mb-[8px] font-bold`}>Reload Needed</div>
            <div className={tw`mb-[8px]`}>
              Before you can continue, you'll need to reload the page.
            </div>
            <div className={tw`mb-[16px]`}>
              <strong>Note</strong>: you'll need to be online to reload.
            </div>
            <div>
              <AsyncIconButton
                initialIcon={'fa-sync-alt'}
                buttonStyle={'primary-fill'}
                onClick={() => window.location.reload()}
              >
                Reload Page
              </AsyncIconButton>
            </div>
          </div>
        </div>
      </AnimatedPopup>
    )
  }
}

const ensureUserMatches = async ({
  user,
  setAsOutOfSync,
}: {
  user: UserAccount
  setAsOutOfSync: () => void
}) => {
  try {
    const userSessionDataMatches = await ensureCachedUserMatchesSession(user)
    if (!userSessionDataMatches) {
      setAsOutOfSync()
      await clearEmarRuntimeCache()
      await clearCareAppUserAccountCache()
    }
  } catch (error) {
    if (error === 'No current user') {
      ErrorMonitoring.capture({
        error:
          'No current user in ensureCachedUserMatchesAuthToken -- ignoring',
        level: 'log',
      })
    } else {
      ErrorMonitoring.capture({
        error,
        extras: {
          source: 'ensureCachedUserMatchesAuthToken in UserSessionSynchronizer',
        },
      })
    }
  }
}

const ensureCachedUserMatchesSession = async (
  currentUser: UserAccount
): Promise<boolean> => {
  const username = await getAuthenticatedUser()
  if (!username) {
    return true
  }

  const doesMatch = username === currentUser.username
  if (!doesMatch) {
    ErrorMonitoring.capture({
      error: 'User session out of sync',
      level: 'warning',
      extras: {
        cognitoUsername: username,
        cachedUsername: currentUser.username ?? 'No username',
      },
    })
  }
  return doesMatch
}
